import slugify from 'slugify';
import {
  getDestinationName, isAllValuesFalse, objectIsNotEmpty, objectToActiveKeysArray, removeProps,
} from './helper-methods';
import destinationsService from '../services/destinationsService';
import { PropertyCategories, PropertyCategoriesForRemoving } from '../constans/property';
import { cleanedUrl } from '../constans/locations';
import propertyTypes from '../constans/property-types';

export const paginationLimit = 10;
export const getter = async ({
  filters: selectedCategories, query, offset, ids,
}) => {
  if (ids.page) {
    delete ids.page;
  }

  const categoriesFilters = objectToActiveKeysArray(selectedCategories);

  const filters = removeProps(categoriesFilters, PropertyCategoriesForRemoving);

  const { data, pagination } = await destinationsService()
    .getDestinationProperties({
      ...ids,
      offset,
      categoriesFilters: filters,
      limit: paginationLimit,
      q: query?.trim().length ? query : null,
    });

  const processedData = data.map(({
    id,
    title,
    category,
    photo,
    country,
    region,
    city,
    district,
    urlDestination,
    urlName,
  }) => {
    const cityPart = cleanedUrl(`${urlDestination || city?.name || region?.name || country?.name}`);
    const propertyPart = cleanedUrl(`${urlName || title}`);
    const link = `/properties/${cityPart}/${propertyPart}`;
    return {
      id,
      title,
      subtitle: PropertyCategories[category],
      address: getDestinationName(district, city, region, country),
      mainPhotoLink: photo?.file?.link,
      city,
      region,
      country,
      urlDestination,
      urlName,
      link,
    };
  });

  return { data: processedData, pagination };
};

export const activityGetter = async ({
  filters,
  query,
  offset,
  limit,
  ids,
}) => {
  let categoriesFilters = [];
  if (objectIsNotEmpty(filters)) {
    categoriesFilters = removeProps(objectToActiveKeysArray(filters), Object.keys(propertyTypes));
  }

  if (objectIsNotEmpty(filters) && isAllValuesFalse(filters)) {
    delete ids.categoriesFilters;
  }

  if (ids.page) {
    delete ids.page;
  }
  const { data, pagination } = await destinationsService()
    .getDestinationActivities({
      ...ids,
      ...categoriesFilters?.length && { categoriesFilters },
      offset,
      limit: paginationLimit,
      q: query?.trim().length ? query : null,
    });

  const processedData = data.map(({
    id,
    category,
    title,
    district,
    city,
    region,
    country,
    photo,
    payingOrFree,
    urlDestination,
    urlName,
  }) => {
    const cityPart = slugify(`${urlDestination || city?.name || region?.name || country?.name}`);
    const propertyPart = slugify(`${urlName || title}`);
    const link = `/activities/${cityPart}/${propertyPart}`;
    return {
      id,
      title,
      payingOrFree,
      subtitle: category,
      mainPhotoLink: photo?.file?.link,
      address: getDestinationName(district, city, region, country),
      country,
      region,
      city,
      district,
      urlDestination,
      urlName,
      link,
    };
  });

  return {
    data: processedData,
    pagination,
  };
};
