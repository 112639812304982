import React, { useCallback, useEffect, useState } from 'react';
import styles from '../Marketing/index.module.scss';
import customStyles from '../Home/index.module.scss';
import StorageService from '../../services/storage.service';
import { STORAGE_DESTINATION } from '../DestinationTree';
import { getShortDestinationNameAndId } from '../../helpers/helper-methods';
import SearchInput from '../Search';
import SearchFilters from '../SearchFilters/SearchFilters';
import SimpleSearchResultCard from '../SimpleSearchResultCard';
import { free } from '../../constans/activity-review';
import Pagination from '../Pagination';
import useDebounce from '../../hooks/useDebounce';
import {
  PROPERTY_CATEGORY_FILTER_INITIAL_VALUES,
  PROPERTY_CATEGORY_OPTIONS,
} from '../../constans/property';
import {
  cardClick,
  getIds,
  getLocationName,
} from '../../helpers/generateLinks';
import { activityGetter, getter, paginationLimit } from '../../helpers/getter';
import ButtonsGroup from '../ButtonGroup/ButtonGroup';
import {
  ACTIVITY_CATEGORY_FILTER_INITIAL_VALUES,
  ACTIVITY_CATEGORY_OPTIONS,
} from '../../constans/activity';
import {
  VideoEmulatorLaptop,
  VideoEmulatorMobile,
} from '../VideoImulator/VideoEmulator';
import http from '../../services/http.service';
import Button from '../UI/Button/Button';
import ExploreTab from '../ExploreTab';
import {
  SeoDataTag,
  SeoDescTag,
  SeoTitle,
} from '../StructuredData/StructuredData';

const storageService = StorageService();

const options = ['activity', 'property'];

const desktopVideoLink =
  'https://s3.ap-east-1.amazonaws.com/bucket-koralgo-stage/files/user_1/koralgo_file_30a7e856-9484-45c5-aa3a-7de5835cbd06.mp4';
const mobileVideoLink =
  'https://s3.ap-east-1.amazonaws.com/bucket-koralgo-stage/files/user_1/koralgo_file_c3a2677b-0d21-4641-9186-a4abfc4bfdca.mp4';

function Home({ destination }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [propertiesFilters, setPropertiesFilters] = useState({});
  const [activitiesFilters, setActivitiesFilters] = useState({});
  const [results, setResults] = useState([]);
  const [activitiesResults, setActivitiesResults] = useState([]);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [activitiesTotalCount, setActivitiesTotalCount] = useState(0);
  const debouncedQuery = useDebounce(searchQuery, 300);
  const [currentTab, setCurrentTab] = useState(options[0]);
  const [loading, setLoading] = useState(true);
  const [destinationDescription, setDestinationDescription] = useState();
  const [destinationData, setDestinationData] = useState();
  const [isShowDemo, setIsShowDemo] = useState(true);

  const ids = getIds(destination);
  const location = getShortDestinationNameAndId(ids);

  useEffect(() => {
    const getLocationDetails = async () => {
      const { id } = location;
      const dest = await http.get(`/${location.patch}/${id}`);
      setDestinationDescription(dest?.data?.descriptionMain || '');
      setDestinationData(dest?.data);
    };
    if (location?.id) {
      getLocationDetails();
    }
  }, []);

  const getPropertiesData = async ({ selected = 0 }) => {
    setLoading(true);
    const queryData = {
      filters: propertiesFilters,
      query: debouncedQuery,
      offset: paginationLimit * selected,
      limit: paginationLimit,
      ids,
    };

    const { data, pagination } = await getter(queryData);

    setPage(selected);
    setTotalCount(pagination?.totalCount || 0);
    setResults(data);
    setLoading(false);
  };

  const getActivitiesData = async ({ selected = 0 }) => {
    setLoading(true);
    const queryData = {
      filters: activitiesFilters,
      query: debouncedQuery,
      offset: paginationLimit * selected,
      limit: paginationLimit,
      ids,
    };

    const { data: activities, pagination } = await activityGetter(queryData);

    setPage(selected);
    setActivitiesTotalCount(pagination?.totalCount || 0);
    setActivitiesResults(activities);
    setLoading(false);
  };

  useEffect(() => {
    getPropertiesData({ filters: propertiesFilters, query: debouncedQuery });
  }, [currentTab, propertiesFilters, debouncedQuery]);

  useEffect(() => {
    getActivitiesData({ filters: activitiesFilters, query: debouncedQuery });
  }, [currentTab, activitiesFilters, debouncedQuery]);

  const currentDestination = {
    country: destination,
    region: destination?.region,
    city: destination?.region?.city,
    district: destination?.region?.city?.district,
  };
  storageService.set(STORAGE_DESTINATION, currentDestination);

  const getInitialFilter = useCallback(() => {
    const initialFilter = ACTIVITY_CATEGORY_FILTER_INITIAL_VALUES;
    if (ids.categoriesFilters?.length) {
      const { categoriesFilters } = ids;
      categoriesFilters.forEach((filter) => {
        initialFilter[filter] = true;
      });
    }
    return initialFilter;
  }, [ids.categoriesFilters]);

  return (
    <div className={`${styles.home} flex-grow-1`}>
      {destinationDescription?.length > 0 && (
        <SeoDescTag description={destinationDescription} />
      )}
      <SeoTitle title={`Plan your trip in ${getLocationName(destination)}`} />
      <SeoDataTag
        metaDescription={destinationData?.metaDescription || ''}
        metaKeyWords={destinationData?.metaKeyWords || ''}
      />
      <div className={`${styles.container} landing-container flex flex-center`}>
        <div className="col-12 pl-0">
          <div className={styles.homeContent}>
            <h1 className={`${styles.descriptionCentered} ${styles.mb6}`}>
              Plan your trip in {getLocationName(destination)}
            </h1>

            <ExploreTab styles={customStyles} withInfo />

            <div
              className={`${styles.containerVide} ${
                !isShowDemo && styles.hidden
              }`}
            >
              <div className={styles.block}>
                <VideoEmulatorLaptop
                  imgWidth={814}
                  imgHeight={420}
                  videoWidth={650}
                  videoHeight={420}
                  link={desktopVideoLink}
                  videoStyle={styles.videoStyle}
                />
              </div>

              <VideoEmulatorMobile link={mobileVideoLink} />
            </div>

            <div
              className={`${styles.containerVide} ${isShowDemo && styles.mt6}`}
            >
              <Button
                type="submit"
                onClick={() => {
                  setIsShowDemo(!isShowDemo);
                }}
                classes={`t-600 btn btn_primary-danger uppercased-text ${styles.submitButton}`}
              >
                {isShowDemo ? 'Hide demo' : 'Show demo'}
              </Button>
            </div>
          </div>

          {destinationDescription?.length > 0 && (
            <p className={`${styles.textDescription} ${styles.pad}`}>
              {destinationDescription}
            </p>
          )}

          <div>
            <div className={`flex flex-between w-100 ${styles.searchWrapper}`}>
              <h2 className={`${styles.mainText} ${styles.minWidth70}`}>
                <ButtonsGroup
                  options={options}
                  labels={[
                    `${activitiesTotalCount} activities`,
                    `${totalCount} properties`,
                  ]}
                  setCurrentTab={setCurrentTab}
                  className={styles.buttonsGroup}
                />
                in {getLocationName(destination)}
              </h2>

              <div className={styles.searchBlock}>
                <SearchInput
                  containerStyles={styles.search}
                  isSimpleSearch
                  placeholder="Search"
                  onChange={(event) =>
                    setSearchQuery(event.currentTarget.value)
                  }
                  value={searchQuery}
                  type="text"
                />
                <div className={`flex flex-v-center ${styles.filterBlock}`}>
                  <span className={`${styles.mainText} ${styles.filter}`}>
                    Filter
                  </span>
                  <SearchFilters
                    resetButtonText="No filter"
                    onSubmit={(values) =>
                      currentTab === options[0]
                        ? setActivitiesFilters(values)
                        : setPropertiesFilters(values)
                    }
                    initialValues={
                      currentTab === options[0]
                        ? getInitialFilter()
                        : PROPERTY_CATEGORY_FILTER_INITIAL_VALUES
                    }
                    filterOptions={[
                      {
                        name: 'values',
                        options:
                          currentTab === options[0]
                            ? ACTIVITY_CATEGORY_OPTIONS
                            : PROPERTY_CATEGORY_OPTIONS,
                      },
                    ]}
                  />
                </div>
              </div>
            </div>

            <div className={styles.results}>
              {currentTab === options[0] &&
                !loading &&
                activitiesResults.map(
                  ({
                    id,
                    title,
                    subtitle,
                    address,
                    mainPhotoLink,
                    payingOrFree,
                    city,
                    region,
                    country,
                    urlDestination,
                    urlName,
                    link,
                  }) => (
                    <div className={styles.resultsItem} key={id}>
                      <SimpleSearchResultCard
                        onClick={(event) =>
                          cardClick(
                            title,
                            city,
                            region,
                            country,
                            urlDestination,
                            urlName,
                            event,
                            true
                          )
                        }
                        isFree={payingOrFree === free}
                        subtitle={subtitle}
                        title={title}
                        address={address}
                        mainPhotoLink={mainPhotoLink}
                        href={link}
                        city={city?.name || region?.name || country?.name}
                        isMarketing
                        hideChip
                      />
                    </div>
                  )
                )}

              {currentTab === options[1] &&
                !loading &&
                results.map(
                  ({
                    id,
                    title,
                    subtitle,
                    address,
                    mainPhotoLink,
                    payingOrFree,
                    city,
                    region,
                    country,
                    urlDestination,
                    urlName,
                    link,
                  }) => (
                    <div className={styles.resultsItem} key={id}>
                      <SimpleSearchResultCard
                        onClick={(event) =>
                          cardClick(
                            title,
                            city,
                            region,
                            country,
                            urlDestination,
                            urlName,
                            event
                          )
                        }
                        isFree={payingOrFree === free}
                        subtitle={subtitle}
                        title={title}
                        address={address}
                        mainPhotoLink={mainPhotoLink}
                        href={link}
                        city={city?.name || region?.name || country?.name}
                        isMarketing
                        hideChip
                      />
                    </div>
                  )
                )}
            </div>
            <div className=" flex flex-center">
              <Pagination
                callback={(v) =>
                  currentTab !== options[0]
                    ? getPropertiesData({
                        filters: propertiesFilters,
                        query: debouncedQuery,
                        ...v,
                      })
                    : getActivitiesData({
                        filters: activitiesFilters,
                        query: debouncedQuery,
                        ...v,
                      })
                }
                total={
                  currentTab === options[0] ? activitiesTotalCount : totalCount
                }
                perPage={paginationLimit}
                forcePage={Number(page)}
                isMarketing
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
