import React, { useEffect } from 'react';
import { getQueryParams } from '../../helpers/helper-methods';
import CookiesService from '../../services/cookie.service';
import {
  COOKIES_KEYS,
  COOKIES_LIFETIME,
  SESSIONSTORAGE_KEY,
} from '../../constans/localstorage';
import http from '../../services/http.service';
import { API } from '../../constans/http';
import SessionService from '../../services/session.service';
import RoleBasedRoute from '../../components/RoleBasedRoute';
import Layout from '../../components/Layout/Layout';
import { nonSellerRoles } from '../../constans/roles';
import Home from '../../components/LandingItineraries';
import {
  SeoData,
  SeoDataTag,
} from '../../components/StructuredData/StructuredData';

function Index({ pageContext }) {
  const { destination, urlPath } = pageContext;
  const search = '';
  const { BP } = getQueryParams(search);
  const partnerNickname = CookiesService().getCookie(
    COOKIES_KEYS.PARTNER_NICKNAME
  );

  let metaDescription = '';
  let metaKeyWords = '';

  if (destination?.region?.city?.district) {
    metaDescription = destination?.region?.city?.district?.metaDescription;
    metaKeyWords = destination?.region?.city?.district?.metaKeyWords;
  } else if (destination?.region?.city) {
    metaDescription = destination?.region?.city?.metaDescription;
    metaKeyWords = destination?.region?.city?.metaKeyWords;
  } else if (destination?.region) {
    metaDescription = destination?.region?.metaDescription;
    metaKeyWords = destination?.region?.metaKeyWords;
  }

  useEffect(() => {
    if (BP) {
      CookiesService().setCookie(
        COOKIES_KEYS.PARTNER_NICKNAME,
        BP,
        COOKIES_LIFETIME
      );

      http
        .get(`${API.PARTNERS}/${BP}/logo`, { useToast: false })
        .then(({ data }) => {
          const { file } = data;
          if (file.link) {
            SessionService().set(SESSIONSTORAGE_KEY.PARTNER_LOGO, file.link);
            SessionService().set(
              SESSIONSTORAGE_KEY.LOGO_SUBTITLE,
              data.subTitle
            );
          }
        });
    }
  }, []);

  useEffect(() => {
    if (partnerNickname && !BP) {
      http
        .get(`${API.PARTNERS}/${partnerNickname}/logo`, { useToast: false })
        .then(({ data }) => {
          const { file } = data;
          if (file.link) {
            SessionService().set(SESSIONSTORAGE_KEY.PARTNER_LOGO, file.link);
            SessionService().set(
              SESSIONSTORAGE_KEY.LOGO_SUBTITLE,
              data.subTitle
            );
          }
        });
    }
  }, []);

  return (
    <RoleBasedRoute
      component={() => (
        <div className="column-flexed-wrapper">
          <SeoDataTag
            metaDescription={metaDescription}
            metaKeyWords={metaKeyWords}
          />
          <SeoData urlPath={urlPath} />
          <Layout>
            <Home destination={destination} />
          </Layout>
        </div>
      )}
      availableRoles={nonSellerRoles}
    />
  );
}

export default Index;
