import React from 'react';

import PlaceholderImg from '../../assets/img/placeholder.svg';
import { Free, Paid } from '../../constans/activity';

import styles from './index.module.scss';

function SimpleSearchResultCard({
  subtitle,
  mainPhotoLink,
  title,
  hideChip,
  onClick,
  isFree,
  isSelected = false,
  href = '',
  city,
  isMarketing = false,
}) {
  return (
    <a href={href} className={`${styles.linkBlock}`}>
      <div
        className={`${styles.activityCard} ${isSelected && styles.activitySelectedCard} ${isMarketing && styles.activityMarketingCard}`}
        onClick={onClick}
      >
        {mainPhotoLink
          ? <img src={mainPhotoLink} className={styles.image} alt="property" />
          : <img src={PlaceholderImg} alt="placeholder" className={`${styles.image} ${styles.placeholder}`} />}

        <div className={styles.info}>
          <div className={`flex flex-between ${styles.infoColumn} ${isMarketing && styles.infoMarketing}`}>
            <div className={styles.infoTitle}>
              {title}
            </div>
            <div className={styles.infoCategory}>
              {subtitle}
            </div>
          </div>

          <div className={styles.activityLine} />

          <div className={`flex flex-between flex-v-center ${styles.bottomBlock}`}>
            <div className={styles.address}>
              {city}
            </div>
            {!hideChip && (
            <div className={styles.chip}>
              {isFree ? Free : Paid}
            </div>
            )}
          </div>
        </div>
      </div>
    </a>
  );
}

export default SimpleSearchResultCard;
